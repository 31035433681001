import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo/seo';
import Categories from '../components/categories/categories';
import Hero from '../components/hero/hero';
import InterviewGrid from '../components/interview-grid/interview-grid';
import './index.scss'

export default ({ data, location }) => {
  const interviews = data.allInterviewsJson.nodes;

  return (
    <Layout>
      <SEO
        title="Diverse nettbutikker"
        keywords={[`interviews`, `entrepreneurs`, `startups`]}
        imageURL={data.file.publicURL}
      />
      <section className="hero">
        <div className="hero-body">
          <div className="container has-text-centered">
            <Categories/>
          </div>
        </div>
      </section>
      <div style={{ margin: '1rem' }}>
        <InterviewGrid items={interviews} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "images/logo-og.png" }) {
      publicURL
    }
    allInterviewsJson(
      sort: { fields: timestamp, order: DESC },
      filter: {categories: {in: "diverse"}}
    ) {
      nodes {
        slug
        url
        name
        title
        position
        avatar {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
